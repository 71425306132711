import useVisible from "../../hooks/useVisible.jsx";

function DeleteFilter({ selectedTag, buttonTag, generateButtonTags }) {
  const [isVisible, setIsVisible] = useVisible("DeleteFilterVisibility");

  const handleClick = (script, argument) => {
    window.jsx.evalFile("./Harchenko/AE2 Extension/ppData.jsx");
    window.jsx.evalScript(`run(${script}(${argument}));`, function (result) {});
  };

  if (selectedTag && selectedTag !== buttonTag) {
    return null;
  }

  return (
    <>
      <li
        onClick={() => setIsVisible(!isVisible)}
        className={`button-card resolution-toggle ${isVisible ? "active" : ""}`}
      >
        <div className="button-card__icon">
        <i class="fa-regular fa-trash"></i>
          <div />
        </div>
        <div className="button-card__title">Delete Filter</div>
        {generateButtonTags()}
      </li>
      <div className={`resolution-tools ${isVisible ? "" : "hidden"}`}>
        <div
          onClick={() =>
            handleClick(
              "deleteFilter"
            )
          }
          className="resolution-tools__button"
        >
          Delete Offline
        </div>
        <div
          onClick={() => handleClick("deleteFilter", JSON.stringify('adobe'))}
          className="resolution-tools__button"
        >
          Delete for Mac
        </div>
      </div>
    </>
  );
}

export default DeleteFilter;
