import "./App.css";
import mainApi from "../../utils/api.js";
import CurrentUserContext from "../../contexts/CurrentUserContext.jsx";
import { useState, useEffect } from "react";
import {
  Navigate,
  useRoutes,
  useNavigate,
  useLocation,
} from "react-router-dom";
import useVisible from "../../hooks/useVisible.jsx";

import Login from "../Login/Login.jsx";
import Register from "../Register/Register.jsx";
import InfoTooltip from "../InfoTooltip/InfoTooltip.jsx";
import SubscriptionCheck from "../SubscriptionCheck/SubscriptionCheck.jsx";
import Header from "../Header/Header.jsx";
import Tags from "../Tags/Tags.jsx";
import ButtonList from "../ButtonList/ButtonList.jsx";
import Footer from "../Footer/Footer.jsx";
import LoadData from "../LoadData/LoadData.jsx";
import Preloader from "../Preloader/Preloader.jsx";

export default function App() {
  const navigate = useNavigate();
  const location = useLocation();

  const [selectedCategory, setSelectedCategory] = useState(
    localStorage.getItem("selectedCategory") || "ae"
  );

  const [selectedTag, setSelectedTag] = useVisible(null);

  const [buttons, setButtons] = useState([]);
  const [isButtonsUpdated, setIsButtonsUpdated] = useState(false);

  const [isLoader, setIsLoader] = useState(false);
  const [loading, setLoading] = useState(true);
  const [dataLoaded, setDataLoaded] = useState(false);

  const [isInfoTooltip, setIsInfoTooltip] = useState({
    isOpen: false,
    status: true,
    text: "",
  });

  const [load, setLoad] = useState(false);
  const [currentUser, setCurrentUser] = useState({});
  const [loggedIn, setLoggedIn] = useState(false);
  const [serverError, setServerError] = useState(null);

  function closeInfoTooltip() {
    setIsInfoTooltip({ ...isInfoTooltip, isOpen: false });
  }

  function handleRegister({ name, family, login, email, password }) {
    setIsLoader(true);
    mainApi
      .createUser({ name, family, login, email, password })
      .then((response) => {
        if (response.data.email === email) {
          handleLogin({ email, password });
        }
      })
      .catch((err) => setServerError(err.message))
      .finally(() => setIsLoader(false));
  }

  function handleLogin({ email, password }) {
    setIsLoader(true);
    mainApi
      .login({ email, password })
      .then((jwt) => {
        if (jwt.token) {
          localStorage.setItem("jwt", jwt.token);
          return mainApi.getUserInfo();
        }
        // Если нет jwt.token, возможно, следует выбросить ошибку или обработать этот случай иначе.
        throw new Error("JWT token is missing.");
      })
      .then((data) => {
        if (data) {
          setLoggedIn(true);
          setCurrentUser(data);
          navigate("/", { replace: true });
        }
      })
      .catch((err) => {
        setServerError(err.message);
        // Если возникла ошибка при входе или получении данных пользователя, может быть полезно очистить jwt из localStorage
        localStorage.removeItem("jwt");
      })
      .finally(() => {
        setIsLoader(false);
      });
  }

  function handleSignOut() {
    setCurrentUser({});
    setLoggedIn(false);
    localStorage.removeItem("jwt");
  }

  useEffect(() => {
    if (process.env.NODE_ENV !== "production") {
      setDataLoaded(true);
    }
  }, []);

  useEffect(() => {
    const path = location.pathname;
    const jwt = localStorage.getItem("jwt");
    if (jwt) {
      setIsLoader(true);
      mainApi
        .getUserInfo()
        .then((data) => {
          if (data) {
            setLoggedIn(true);
            setCurrentUser(data);
            navigate(path, { replace: true });

            const status = data.subscription.status;
            const endDates = data.subscription.end;

            if (endDates && endDates.length > 0) {
              const lastEndDate = new Date(endDates[endDates.length - 1]);
              const now = new Date();

              if (now > lastEndDate && status) {
                data.subscription.status = false;
                data.subscription.ae = false;
                data.subscription.pp = false;
                data.subscription.mg = false;
                data.subscription.am = false;
                data.subscription.dr = false;
                setCurrentUser(data);
                const dataToUpdate = {
                  subscription: {
                    status: false,
                    ae: false,
                    pp: false,
                    mg: false,
                    am: false,
                    dr: false,
                  },
                };

                mainApi
                  .updateSubscription(data._id, dataToUpdate)
                  .then((response) => {
                    console.log("Subscription updated for user:", data._id);
                  })
                  .catch((error) => {
                    console.error(
                      "Failed to update subscription for user:",
                      data._id,
                      error
                    );
                  });
              }
            }
          }
        })
        .catch((err) =>
          setIsInfoTooltip({ isOpen: true, status: false, text: err })
        )
        .finally(() => {
          setIsLoader(false);
          setLoad(true);
          setLoading(false);
        });
    } else {
      setLoad(true);
    }
  }, []);

  useEffect(() => {
    const fetchButtons = async () => {
      try {
        setIsLoader(true);

        let fetchedButtons = await mainApi.getButtons();
        fetchedButtons = fetchedButtons.sort((a, b) =>
        a.name.localeCompare(b.name)
        );

        setButtons(fetchedButtons);
        setIsButtonsUpdated(false); // Reset the flag after successfully fetching the buttons
      } catch (err) {
        setIsInfoTooltip({ isOpen: true, status: false, text: err });
      } finally {
        setIsLoader(false);
      }
    };

    if (loggedIn) {
      fetchButtons();
    }
  }, [loggedIn, isButtonsUpdated]);

  const routing = useRoutes([
    {
      path: "signup",
      element: loggedIn ? (
        <Navigate to="/" replace />
      ) : (
        <Register handleRegister={handleRegister} serverError={serverError} />
      ),
    },
    {
      path: "signin",
      element: loggedIn ? (
        <Navigate to="/" replace />
      ) : (
        <Login handleLogin={handleLogin} serverError={serverError} />
      ),
    },
    {
      path: "/",
      element: loggedIn ? (
        currentUser.subscription.status ? (
          dataLoaded ? (
            <>
              <Header handleSignOut={handleSignOut} />
              <Tags currentTag={selectedTag} onTagChange={setSelectedTag} />
              <ButtonList
                buttons={buttons}
                selectedCategory={selectedCategory}
                setSelectedCategory={setSelectedCategory}
                selectedTag={selectedTag}
              />
              <Footer />
            </>
          ) : (
            process.env.NODE_ENV === "production" && (
              <LoadData
                isOpen={!dataLoaded}
                onDataLoaded={() => setDataLoaded(true)}
              />
            )
          )
        ) : (
          <SubscriptionCheck handleSignOut={handleSignOut} />
        )
      ) : (
        <Navigate to="/signin" />
      ),
    },
  ]);

  return (
    <CurrentUserContext.Provider value={currentUser}>
      <div>
        <InfoTooltip onClose={closeInfoTooltip} isInfoTooltip={isInfoTooltip} />
        {isLoader ? <Preloader isOpen={!dataLoaded} /> : routing}
      </div>
    </CurrentUserContext.Provider>
  );
}
